import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const HeaderText = styled(Link)`
  :before {
    visibility: hidden;
  }
`

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 960;
  padding: 1.45rem 1.0875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#68ed68`,
      marginBottom: `1.45rem`,
    }}
  >
    <HeaderContainer>
      <h1 style={{ margin: 0 }}>
        <HeaderText
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </HeaderText>
      </h1>
    </HeaderContainer>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
